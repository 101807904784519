import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import Boomerang from './components/boomerang/Boom'
import { DailyProspecting } from './components/daily_prospecting/DailyPros'
import CalendarPort from './components/calendarPort/CalendarPort'
import CalendarBig from './components/calendar/CalendarBig'
import Demographics from './components/demographics/Demographics'
import Zipcode from './components/zipcode/Zipcode'
import Otbcounts from './components/otb/OtbCounts'
import Rfmcounts from './components/rfm/RfmCounts'
import Triggers from './components/triggers/Triggers'
import Tentpole from './components/tentpole/Tentpole'
import ResponseDemo from './components/tentpole/ResponseDemo'
import CustRet from './components/customerRetention/CustRet'
import NewCustomerGrowth from './components/customerRetention/NewCustomerGrowth'
import LandingPage from './components/login/LandingPage'
import Home from './components/home/Home'
import Layout from './components/layout'
import errorPage from './assets/404Error.png'
import TabTest from './components/TabTest'
import CreateUser from './components/CreateUser'
import UpdateUser from './components/UpdateUser'
import TableauCatalog from './components/TableauCatalog'
import SalesPersonsDirectory from './components/salesPersons/SalesPersonDirectory'
import EditSalesPerson from './components/salesPersons/EditSalesPerson'
import AddSalesPerson from './components/salesPersons/AddSalesPerson'
import LicenseeParticipation from './components/licensee/LicenseeParticipation'
import LtvDemo from './components/demographics/LtvDemo'
import DemoTrends from './components/demographics/DemoTrends'
import LostOpp from './components/lostOpp/LostOpp'
import BoomLostOpp from './components/boomerang/BoomerangLostOpp'
import CprStats from './components/cpr/CprStats'
import ProductPerformance from './components/prodPerformance/ProductPerformance'
import PromoCal from './components/calendar/PromoCal'
import LtvAFHS from './components/tableauScreens/LtvAfhscorp'
import NatBoomAFHS from './components/tableauScreens/NatBoomAfhscorp'
import CustomersAFH from './components/tableauScreens/CustomersAFH'
import NatCatalog from './components/tableauScreens/NatCatalogAFH'
import RFMSalesStatsRoom from './components/tableauScreens/RFMSalesStatsRoom'
import Digital from './components/Digital'
import AtrylixWidgets from './components/AtrylixWidgets'

import DemoByLoc from './components/demographics/DemoByLoc'
// import IdleAlert from './components/IdleAlert'
// import IdleTimer from './components/IdleTimer'
import {
  withStyles,
  CardMedia,
  Typography,
  Button,
  Link,
  Paper,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core'

const HomeButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 13,
    fontWeight: 'bold',
    width: '15%',
    height: 50,
    border: '1.5px solid',
    color: '#4ab8bb',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4ab8bb',
      borderColor: '#4ab8bb',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none'
    },
  }
})(Button)

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        Cookies.get('token')
        ? <Component {...props} />
        : <Redirect to='/login' />
      )}
    />
)

// const NoMatch = ({location}) => (
//   <div>
//     <CardMedia
//       component="img"
//       image={errorPage}
//     />
//     <div style={{textAlign: 'center', justifyContent: 'center', display: 'block'}}>
//       <Typography variant="caption" style={{fontSize: '16px', fontWeight: 'bold', color: '#757575'}}>
//         <span>The page you are looking for might have been removed, had its name</span><br />
//         <span>changed or is temporarily unavailable.</span>
//       </Typography>
//       <div style={{marginTop: '25px'}}>
//         <Link component={HomeButton} href='/' underline='none'>GO BACK</Link>
//       </div>
//     </div>
//   </div>
// )

class MainRouter extends Component {
  _isMounted = false

  constructor() {
    super()

    this.state = {
      loggedInStatus: 'not-logged-in',
      currentUser: {},
      tableauScreens: [],
      darkMode: false,
      isTimeout: false,
      openDialog: false,
      seconds: 0,
    }
    this.timer = 0

    this.handleLogin = this.handleLogin.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  componentDidMount = () => {
    // if(Cookies.get('token')) {
    //   const timer = new IdleTimer({
    //     timeout: 30 * 60,
    //     onTimeout: () => {
    //       this.setState({
    //         isTimeout: true,
    //         openDialog: true,
    //         seconds: 30,
    //       })
    //       this.startTimer()
    //     }
    //   })

    //   return () => {
    //     timer.cleanUp()
    //   }
    // }
  }

  handleLogin(data) {
    this.setState({
      loggedInStatus: 'logged-in',
      currentUser: data
    })
    Cookies.set('token', this.state.currentUser.TOKEN, {expires: 1}, {secure: true})

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/user', {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        currentUser: data,
        tableauScreens: data[0].tableau
      })
      localStorage.setItem('tabScreens', JSON.stringify(this.state.tableauScreens))
      localStorage.setItem('currentUser', JSON.stringify(this.state.currentUser))
      // const timer = new IdleTimer({
      //   timeout: 30 * 60,
      //   onTimeout: () => {
      //     this.setState({
      //       isTimeout: true,
      //       openDialog: true,
      //       seconds: 30,
      //     })
      //     this.startTimer()
      //   }
      // })

      // return () => {
      //   timer.cleanUp()
      // }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleLogout() {
    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/logout', {
      method: 'DELETE',
      headers: {
        'token': Cookies.get('token')
      }
    })
    .then(response => {
      if(response.ok === true) {
        return response
      }
    })

    this.setState({
      loggedInStatus: 'logged-out',
      currentUser: {},
      openDialog: false,
    })
    Cookies.remove('token')
    localStorage.removeItem('tabScreens')
    localStorage.removeItem('currentUser')
    localStorage.removeItem('_expiredTime')
  }



  handleDarkModeToggle = () => {
    this.setState({
      darkMode: !this.state.darkMode
    })
  }

  handleCloseDialog = () => {
    window.location.reload()

    this.setState({
      openDialog: false
    })
  }

  startTimer = () => {
    if(this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.handleCountDown, 1000)
    }
  }

  handleCountDown = () => {
    let seconds = this.state.seconds - 1
    this.setState({
      seconds: seconds
    })

    if(this.state.seconds === 0) {
      clearInterval(this.timer)
      this.handleLogout()
      window.location.reload()
    }
  }



  render() {
    const tabRoutes = localStorage.getItem('tabScreens')

    const theme = createMuiTheme({
      palette: {
        type: JSON.parse(localStorage.getItem('darkMode')) ? 'dark' : this.state.darkMode ? 'dark' : 'light'
      }
    })
    return(
      <ThemeProvider theme={theme}>
      {/* <IdleAlert
        openDialog={this.state.openDialog}
        handleCloseDialog={this.handleCloseDialog}
        handleLogout={this.handleLogout}
        seconds={this.state.seconds}
      /> */}
      <Router>
        <Switch>
          <Route
            exact
            path={"/login"}
            render={props => (
              <LandingPage {...props} handleLogin={this.handleLogin} loggedInStatus={this.state.loggedInStatus}  />
            )}
          />
          <Layout
            handleLogout={this.handleLogout}
            user={this.state.currentUser}
            darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : this.state.darkMode ? this.state.darkMode : false}
          >
            <PrivateRoute
              exact
              path={"/"}
              component={() => <Home getUserScreens={this.handleCurrentUserScreens} loggedInStatus={this.state.loggedInStatus} currentUser={this.state.currentUser} darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>}
            />
            <PrivateRoute exact path="/boomerang" component={() => <Boomerang darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/trigger" component={() => <Triggers darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/daily-prospecting" component={() => <DailyProspecting darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/event-marketing" component={() => <Tentpole darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/response-demographic" component={() => <ResponseDemo darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/customer-retention" component={CustRet} />
            <PrivateRoute exact path="/otb-counts" component={() => <Otbcounts darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/rfm-counts" component={() => <Rfmcounts darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            {/* <PrivateRoute exact path="/demographic" component={() => <Demographics darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} /> */}
            <PrivateRoute exact path="/demographic" component={() => <DemoByLoc />} />


            {/* <PrivateRoute exact path="/demo-by-loc-update" component={() => <DemoByLoc />} /> */}

            <PrivateRoute exact path="/ltv-by-demo" component={() => <LtvDemo darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/ltv" component={() => <LtvAFHS darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/zip-code" component={Zipcode} />
            <PrivateRoute exact path="/calendar" component={() => <CalendarPort darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/calendar-new" component={() => <CalendarBig darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/new-customer-growth" component={NewCustomerGrowth} />
            <PrivateRoute exact path="/demographic-trends" component={DemoTrends} />
            <PrivateRoute exact path="/boomerang/lost-opp" component={() => <BoomLostOpp darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/product-performance" component={() => <ProductPerformance darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false} />} />
            <PrivateRoute exact path="/tableau-catalog" component={TableauCatalog} />
            <PrivateRoute exact path="/cpr-stats" component={() => <CprStats darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/sales-consultants" component={SalesPersonsDirectory} />
            <PrivateRoute exact path="/add-new-consultant" component={AddSalesPerson} />
            <PrivateRoute exact path="/edit-sales-consultant" component={EditSalesPerson} />
            <PrivateRoute exact path="/licensee-participation" component={LicenseeParticipation} />
            <PrivateRoute exact path="/create-user" component={() => <CreateUser darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/nat-boom" component={() => <NatBoomAFHS darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/rfm-sales-stats-afhs" component={() => <CustomersAFH darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/nat-catalog" component={() => <NatCatalog darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/rfm-sales-stats-room" component={() => <RFMSalesStatsRoom darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/digital" component={() => <Digital darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} />
            <PrivateRoute exact path="/atrylix-widgets" component={() => <AtrylixWidgets darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>} /> 

            <PrivateRoute
              exact
              path="/update-user"
              component={() => <UpdateUser darkModeToggle={this.handleDarkModeToggle} darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false}/>}
            />
            <PrivateRoute exact path="/promo-cal" component={PromoCal} />
            {tabRoutes ?
              JSON.parse(tabRoutes).map(route => (
                <PrivateRoute
                  key={route.ID}
                  exact
                  path={"/" + route.display}
                  pageName={route.short_name}
                  component={() => <TabTest beta={route.beta} viz={route.display} pageName={route.short_name} darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : this.state.darkMode ? this.state.darkMode : false}/>}
                />
              ))
            : null}

            <PrivateRoute              
              exact
              path={"/IFF_02/IFF"}
              pageName={'IFF'}
              component={() => <TabTest viz={'IFF_02/IFF'} pageName={'IFF'} darkMode={localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : this.state.darkMode ? this.state.darkMode : false}/>}
            />


            {/*<PrivateRoute exact path={`:tableauPath} component={TableauScreen} />*/}

            {/*<PrivateRoute exact path="/tableautest" component={TabTest} />*/}
            {/*<PrivateRoute exact path="/demographics" component={DemoByLoc} />*/}

            {/*<PrivateRoute exact path="/zip-code" component={Zipcode} />*/}

            {/* <PrivateRoute
              exact
              path={`/:tableauPath`}
              component={() => <TableauScreen />}
            /> */}

            {/* <Route exact path="/stats" component={Stats} /> */}
            {/* <Route component={NoMatch} /> */}
            {/* <Footer /> */}
          </Layout>
        </Switch>
      </Router>
    {/* </Paper> */}
  </ThemeProvider>
    )
  }
}

export default MainRouter
