import React, { useState } from 'react'
import { TextField, makeStyles, Grid, Box } from '@material-ui/core'
// import { withStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    widgetColumn: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        height: "830px"
    },
    widgetRow: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"    
    },
    itemSpacing: {
        padding: "10px",
        height: "410px",
    }
}))

export default function AtrylixWidgets() {
  const classes = useStyles()
  const widgetToken = "9995db9768e04d78b75437c78066e0c5"// "6ffea0ce3bc9310cb1d658649dc4b68b"
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))?.[0]?.username

  const [visible, setVisible] = useState(false)

  const handleVisible = (key) => {
    if (key == "lsdev") setVisible(true)
  }

  return (
    <div>
        {visible && currentUser == "user5" ? <>
        
            <Grid className={classes.widgetRow}>
            <Grid item xs={12} md={8}>
                <Grid className={classes.widgetColumn}>
                    <Grid xs={12}>
                        <Box>
                                <iframe width="100%" height="830" src={"https://dashboard.atrilyx.com/index.php?module=Widgetize&action=iframe&containerId=VisitOverviewWithGraph&disableLink=1&widget=1&moduleToWidgetize=CoreHome&actionToWidgetize=renderWidgetContainer&idSite=579&period=day&date=yesterday&token_auth="+widgetToken} scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </Box>
                    </Grid>
                    <Grid className={classes.itemSpacing}>
                        <Box>
                                <iframe width="100%" height="410" src={"https://dashboard.atrilyx.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=realtimeMap&idSite=579&period=day&date=yesterday&token_auth="+widgetToken} scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </Box>
                    </Grid>
                    <Grid className={classes.itemSpacing} sx={{height: "340px"}}>
                        <Box>
                                <iframe width="100%" height="410" src={"https://dashboard.atrilyx.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getReferrerType&idSite=579&period=day&date=yesterday&token_auth="+widgetToken} scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid className={classes.widgetColumn}>
                    <Grid className={classes.itemSpacing}>
                        <Box>
                                <iframe width="100%" height="410" src={"https://dashboard.atrilyx.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getSiteSearchKeywords&idSite=579&period=day&date=yesterday&token_auth="+widgetToken} scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </Box>
                    </Grid>
                    <Grid className={classes.itemSpacing}>
                        <Box>
                                <iframe width="100%" height="410" src={"https://dashboard.atrilyx.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Insights&actionToWidgetize=getOverallMoversAndShakers&idSite=579&period=day&date=yesterday&token_auth="+widgetToken} scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid className={classes.widgetContainer}>
                    <Grid item xs={12} sm={12} className={classes.itemSpacing}>
                        <Box>
                                <iframe width="100%" height="510" src={"https://dashboard.atrilyx.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=UsersFlow&actionToWidgetize=getUsersFlow&idSite=579&period=day&date=yesterday&token_auth="+widgetToken} scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid className={classes.widgetContainer}>
                    <Grid item xs={12} sm={12} className={classes.itemSpacing}>
                        <Box>
                                <iframe width="100%" height="510" src={"https://dashboard.atrilyx.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=VisitorInterest&actionToWidgetize=getNumberOfVisitsByDaysSinceLast&idSite=579&period=day&date=yesterday&date=yesterday&token_auth="+widgetToken} scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
        
        
        </> : <TextField onChange={(event) => { handleVisible(event.target.value); }} ></TextField> }
        
      </div>
  )
}
