import React, { Fragment, useEffect } from 'react'
import clsx from 'clsx'
import Hidden from '@material-ui/core/Hidden'
import MenuIcon from '@material-ui/icons/Menu'
import {
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  MenuList,
  Badge,
  CssBaseline,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Menu,
  CircularProgress,
  Collapse
} from '@material-ui/core'
import {grey, blue, teal,} from '@material-ui/core/colors'
import {AccountCircle, Settings, ExitToApp} from '@material-ui/icons'
import NotificationsIcon from '@material-ui/icons/Notifications'
import GroupIcon from '@material-ui/icons/Group'
import AssessmentIcon from '@material-ui/icons/Assessment'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import PieChartIcon from '@material-ui/icons/PieChart'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TimelineIcon from '@material-ui/icons/Timeline'
import Notifications from '../Notifications'
import MoreIcon from '@material-ui/icons/MoreVert'
import FavoriteIcon from '@material-ui/icons/Favorite'
import TrafficIcon from '@material-ui/icons/Traffic'
import ExploreIcon from '@material-ui/icons/Explore'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LaptopIcon from '@material-ui/icons/Laptop'

import VisibilityIcon from '@material-ui/icons/Visibility'
import SyncIcon from '@material-ui/icons/Sync'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import UserProfileMenu from '../UserProfileMenu'
import AdminMenu from '../AdminMenu'
import { Link, useHistory } from "react-router-dom"
import BarChartIcon from '@material-ui/icons/BarChart'
import DashboardIcon from '@material-ui/icons/Dashboard'
import TodayIcon from '@material-ui/icons/Today'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import TelegramIcon from '@material-ui/icons/Telegram'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PublicIcon from '@material-ui/icons/Public'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Cookies from 'js-cookie'
import Footer from '../Footer'
import SvgIcon from '@material-ui/core/SvgIcon'
import { SiTableau } from 'react-icons/si'
import logo from '../../assets/SmartDash.png'
import logoDark from '../../assets/SmartDash3ColorVector_RGB_NoTagline White.png'
import lsLogo from '../../assets/lsDirectNEW.png'
import lsLogoDark from '../../assets/lsDirectNEW_Reverse.png'
import { IoConstructOutline } from 'react-icons/io5'
import { DailyProspecting } from '../daily_prospecting/DailyPros'
import { RiUserSearchFill } from "react-icons/ri";

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  profileDrawerPaper: {
    width: drawerWidth,
    square: true,
  },
  footer: {
    zIndex: theme.zIndex.navBar + 1
  },
  nav: {
    [theme.breakpoints.up('sm')]: {
      width: 0,
      flexShrink: 0,
      zIndex: theme.zIndex.appBar - 1,
    },
    overflowX: 'scroll',
  },
  navBar: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  navOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: 300,
  },
  navClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 1),
    justifyContent: 'flex-end',
    height: 0
  },
  DrawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
    background: `linear-gradient(to right bottom, #FFF, #cfd8dc)`,
    color: grey[600],
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.16), 0 2px 6px rgba(0, 0, 0, 0.23)',
    square: true,
  },
  appBarDark: {
    zIndex: theme.zIndex.drawer - 1,
    background: `linear-gradient(to right bottom, #3a3a3a, #404040)`,
    // background: '#000',
    color: grey[600],
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.16), 0 2px 6px rgba(0, 0, 0, 0.23)',
    square: true,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  chevronIcon: {
    marginLeft: theme.spacing(0),
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(15),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1,1,1,10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 750,
    }
  },
  avatar: {
    width: '100%',
    maxWidth: 360,
    color: '#00147b',
    margin: '1em',
  },
  avatarDark: {
    width: '100%',
    maxWidth: 360,
    color: '#3bb2b4',
    margin: '1em',
  },
  avatarIcon: {
    color: '#00147b',
  },
  avatarIconDark: {
    color: '#3bb2b4',
  },
  header: {
    color: blue[800],
    fontSize: 18,
    '& span': {
      color: teal[400],
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display:'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 0, 5, 0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -120,
    marginRight: 50,
    [theme.breakpoints.down('md')]: {
      marginLeft: -120,
      marginRight: 45,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: -100,
      marginRight: 60
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 85,
  },
  nested: {
    marginLeft: 15,
  },
  nestedIcon: {
    fontSize: '12px',
    '&:hover': {
      color: '#00147b'
    },
  },
  nestedText: {
    fontSize: '11px',
    color: '#757575',
    marginLeft: '-30px',
    '&:hover': {
      color: '#00147b'
    }
  },
  nextedTextDark: {
    fontSize: '11px',
    color: '#fff',
    marginLeft: '-30px',
  },
  betaIcon: {
    fontSize: '9px',
    color: '#d19608',
    marginLeft: '10px',
    marginTop: '-5px',

  },
  menuText: {
    color: '#757575',
    marginLeft: '-15px',
    '&:hover': {
      color: '#00147b'
    }
  },
  menuTextDark: {
    color: '#fff',
    marginLeft: '-15px'
  },
  menu: {
    color: "#607d8b",
  },
  menuIcon: {
    color: '#757575',
    '&:hover': {
      color: '#00147b'
    }
  },
  menuIconDark: {
    color: '#fff'
  }
}))

function BoomIcon(props) {
  return(
    <SvgIcon {...props}>
      <path d="M10 2H4C2.9 2 2 2.9 2 4S2.9 6 4 6H8L10 2M18 2C20.2 2 22 3.8 22 6V12L18 14C18 9.6 14.4 6 10 6L12 2H18M18 20V16L22 14V20C22 21.1 21.1 22 20 22S18 21.1 18 20Z" />
    </SvgIcon>
  )
}

function LtvIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12 2C13.66 2 15 3.34 15 5S13.66 8 12 8 9 6.66 9 5 10.34 2 12 2M20 18L18 12.56C17.65 11.57 17.34 10.71 16 10C14.63 9.3 13.63 9 12 9C10.39 9 9.39 9.3 8 10C6.68 10.71 6.37 11.57 6 12.56L4 18C3.77 19.13 6.38 20.44 8.13 21.19C9.34 21.72 10.64 22 12 22C13.38 22 14.67 21.72 15.89 21.19C17.64 20.44 20.25 19.13 20 18M15.42 17.5L12 21L8.58 17.5C8.22 17.12 8 16.61 8 16.05C8 14.92 8.9 14 10 14C10.55 14 11.06 14.23 11.42 14.61L12 15.2L12.58 14.6C12.94 14.23 13.45 14 14 14C15.11 14 16 14.92 16 16.05C16 16.61 15.78 17.13 15.42 17.5Z" />
    </SvgIcon>
  )
}

function ResponsiveDrawer(props) {
  const { container } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [navOpen, setNavOpen] = React.useState(false)
  const { children } = props
  const [open, setOpen] = React.useState(false)
  const [openNest, setOpenNest] = React.useState(false)
  const [openDemo, setOpenDemo] = React.useState(false)
  const [openMarket, setOpenMarket] = React.useState(false)
  const [openPromotions, setOpenPromotions] = React.useState(false)
  const [openSales, setOpenSales] = React.useState(false)
  const [openTraffic, setOpenTraffic] = React.useState(false)
  const [openYOY, setOpenYOY] = React.useState(false)
  const [openTrafficDash, setOpenTrafficDash] = React.useState(false)
  const [openLocationDetails, setOpenLocationDetails] = React.useState(false)
  const [openShopperTrak, setOpenShopperTrak] = React.useState(false)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [overlap] = React.useState('rectangle')
  const [currentUser, setCurrentUser] = React.useState({currentUser: []})
  const [isLoading, setIsLoading] = React.useState(true)
  const tableauList = localStorage.getItem('tabScreens')
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/user', {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        setCurrentUser(data[0])
        setIsLoading(false)
      })
    }
    fetchData()
  },[])

  const sideNavItems = currentUser.db_owner === "AHS-TRAFFIC" ?
    [{ name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '', divider: true },]
  :
  currentUser.db_owner === 'ESCANABA' ?
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
      { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
      { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
      { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
      { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },      
    ]
    :
    currentUser.db_owner === 'KHOOK' ?
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
      { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
      { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
      { name: 'lost-opp', short_name: 'Boomerang Lost Opp', symbol: <GpsFixedIcon />, path: '/boomerang/lost-opp', category_name: '' },
      { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
      { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },
      { name: 'product-performance', short_name: 'Product Performance', symbol: <AssignmentIcon />, path: '/product-performance', divider: true, category_name: '', level: 2 },
      { name: 'rfm-counts', short_name: 'RFM Counts', symbol: <HowToRegIcon fontSize="small"/>, path: '/rfm-counts', category_name: 'Customers' },
      { name: 'otb-counts', short_name: 'OTB Counts', symbol: <ShoppingCartIcon fontSize="small" />, path: '/otb-counts', divider: true, category_name: 'Customers' },
      // { name: 'cpr-stats', short_name: 'CPR Stats', symbol: <FavoriteIcon fontSize="small" />, path: '/cpr-stats', category_name: 'Demographics' },
      // { name: 'zip-codes', short_name: 'YOY Sales By Zip', symbol: <LocationOnIcon fontSize="small" />, path: '/zip-code', category_name: 'Demographics' },
      // { name: 'demographics', short_name: 'Demo By Location', symbol: <PublicIcon fontSize="small" />, path: '/demographic', category_name: 'Demographics' },
      // { name: 'demographic-trends', short_name: 'Demographic Trends', symbol: <TimelineIcon fontSize="small" />, path: '/demographic-trends', category_name: 'Demographics' },
      // { name: 'ltv-by-demo', short_name: 'LTV By Demographic', symbol: <LtvIcon fontSize="small" />, path: '/ltv-by-demo', category_name: 'Demographics' },
      { name: 'customer-retention', short_name: 'Customer Retention', symbol: <SyncIcon fontSize="small" />, path: '/customer-retention', divider: true, category_name: 'Customers' },
    ]
  :

  currentUser.db_owner === 'DSG' ?
  [
    { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
    { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
    { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
    { name: 'lost-opp', short_name: 'Boomerang Lost Opp', symbol: <GpsFixedIcon />, path: '/boomerang/lost-opp', category_name: '' },
    { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
    { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },
    { name: 'product-performance', short_name: 'Product Performance', symbol: <AssignmentIcon />, path: '/product-performance', divider: true, category_name: '', },
    { name: 'rfm-counts', short_name: 'RFM Counts', symbol: <HowToRegIcon fontSize="small"/>, path: '/rfm-counts', category_name: 'Customers' },
    { name: 'otb-counts', short_name: 'OTB Counts', symbol: <ShoppingCartIcon fontSize="small" />, path: '/otb-counts', divider: true, category_name: 'Customers' },
    { name: 'cpr-stats', short_name: 'CPR Stats', symbol: <FavoriteIcon fontSize="small" />, path: '/cpr-stats', category_name: 'Demographics' },
    { name: 'zip-codes', short_name: 'YOY Sales By Zip', symbol: <LocationOnIcon fontSize="small" />, path: '/zip-code', category_name: 'Demographics' },
    { name: 'demographics', short_name: 'Demo By Location', symbol: <PublicIcon fontSize="small" />, path: '/demographic', category_name: 'Demographics' },
    { name: 'demographic-trends', short_name: 'Demographic Trends', symbol: <TimelineIcon fontSize="small" />, path: '/demographic-trends', category_name: 'Demographics' },
    // { name: 'ltv-by-demo', short_name: 'LTV By Demographic', symbol: <LtvIcon fontSize="small" />, path: '/ltv-by-demo', category_name: 'Demographics' },
    { name: 'customer-retention', short_name: 'Customer Retention', symbol: <SyncIcon fontSize="small" />, path: '/customer-retention', divider: true, category_name: 'Customers' },    
    { name: 'iff', short_name: 'IFF', symbol: <SiTableau fontSize="small" />, path: '/IFF_02/IFF', divider: true, category_name: '' },
  ]
  :
  currentUser.db_owner === 'AFHSCORP' ?
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
      { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
      { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
      { name: 'lost-opp', short_name: 'Boomerang Lost Opp', symbol: <GpsFixedIcon />, path: '/boomerang/lost-opp', category_name: '' },
      { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
      { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },
      { name: 'product-performance', short_name: 'Product Performance', symbol: <AssignmentIcon />, path: '/product-performance', divider: true, category_name: '', },
      { name: 'rfm-counts', short_name: 'RFM Counts', symbol: <HowToRegIcon fontSize="small"/>, path: '/rfm-counts', category_name: 'Customers' },
      { name: 'rfm-sales-1mm', short_name: 'RFM SALES STATS (1MM SAMPLE)', symbol: <SiTableau fontSize="small"/>, path: '/rfm-sales-stats-afhs', category_name: 'Customers' },
      { name: 'otb-counts', short_name: 'OTB Counts', symbol: <ShoppingCartIcon fontSize="small" />, path: '/otb-counts', category_name: 'Customers' },
      { name: 'cpr-stats', short_name: 'CPR Stats', symbol: <FavoriteIcon fontSize="small" />, path: '/cpr-stats', category_name: 'Demographics' },
      { name: 'zip-codes', short_name: 'YOY Sales By Zip', symbol: <LocationOnIcon fontSize="small" />, path: '/zip-code', category_name: 'Demographics' },
      { name: 'demographics', short_name: 'Demo By Location', symbol: <PublicIcon fontSize="small" />, path: '/demographic', category_name: 'Demographics' },
      { name: 'demographic-trends', short_name: 'Demographic Trends', symbol: <TimelineIcon fontSize="small" />, path: '/demographic-trends', category_name: 'Demographics' },
      // { name: 'ltv-by-demo', short_name: 'LTV By Demographic', symbol: <LtvIcon fontSize="small" />, path: '/ltv-by-demo', category_name: 'Demographics' },
      { name: 'customer-retention', short_name: 'Customer Retention', symbol: <SyncIcon fontSize="small" />, path: '/customer-retention', divider: true, category_name: 'Customers' },
      // { name: 'ltv', short_name: 'LTV', symbol: <SiTableau fontSize="small" />, path: '/ltv', divider: true, category_name: 'Customers' },
      { name: 'natboom', short_name: 'Nat Boom ROI', symbol: <SiTableau fontSize="small" />, path: '/nat-boom', divider: true, category_name: 'Dashboard' },
      { name: 'natcatalog', short_name: 'Nat Catalog ROI', symbol: <SiTableau fontSize="small" />, path: '/nat-catalog', divider: false, category_name: 'Dashboard' },
    ]
    :
    currentUser.db_owner === 'ROOMPLACE' ?
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
      { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
      { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
      { name: 'lost-opp', short_name: 'Boomerang Lost Opp', symbol: <GpsFixedIcon />, path: '/boomerang/lost-opp', category_name: '' },
      { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
      { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },
      { name: 'product-performance', short_name: 'Product Performance', symbol: <AssignmentIcon />, path: '/product-performance', divider: true, category_name: '', },
      { name: 'rfm-counts', short_name: 'RFM Counts', symbol: <HowToRegIcon fontSize="small"/>, path: '/rfm-counts', category_name: 'Customers' },
      { name: 'rfm-sales-stats', short_name: 'RFM SALES STATS', symbol: <SiTableau fontSize="small"/>, path: '/rfm-sales-stats-room', category_name: 'Customers' },
      { name: 'otb-counts', short_name: 'OTB Counts', symbol: <ShoppingCartIcon fontSize="small" />, path: '/otb-counts', category_name: 'Customers' },
      { name: 'cpr-stats', short_name: 'CPR Stats', symbol: <FavoriteIcon fontSize="small" />, path: '/cpr-stats', category_name: 'Demographics' },
      { name: 'zip-codes', short_name: 'YOY Sales By Zip', symbol: <LocationOnIcon fontSize="small" />, path: '/zip-code', category_name: 'Demographics' },
      { name: 'demographics', short_name: 'Demo By Location', symbol: <PublicIcon fontSize="small" />, path: '/demographic', category_name: 'Demographics' },
      { name: 'demographic-trends', short_name: 'Demographic Trends', symbol: <TimelineIcon fontSize="small" />, path: '/demographic-trends', category_name: 'Demographics' },
      // { name: 'ltv-by-demo', short_name: 'LTV By Demographic', symbol: <LtvIcon fontSize="small" />, path: '/ltv-by-demo', category_name: 'Demographics' },
      { name: 'customer-retention', short_name: 'Customer Retention', symbol: <SyncIcon fontSize="small" />, path: '/customer-retention', divider: true, category_name: 'Customers' },
    ]
    :    
    currentUser.db_owner === 'FREEPEOPLE' ?
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
      { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
      { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
      { name: 'lost-opp', short_name: 'Boomerang Lost Opp', symbol: <GpsFixedIcon />, path: '/boomerang/lost-opp', category_name: '' },
      { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
      { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },
      { name: 'product-performance', short_name: 'Product Performance', symbol: <AssignmentIcon />, path: '/product-performance', category_name: '', },
      { name: 'digital', short_name: 'Digital', symbol: <LaptopIcon />, path: '/digital', divider: true, category_name: '', },
      { name: 'rfm-counts', short_name: 'RFM Counts', symbol: <HowToRegIcon fontSize="small"/>, path: '/rfm-counts', category_name: 'Customers' },
      { name: 'otb-counts', short_name: 'OTB Counts', symbol: <ShoppingCartIcon fontSize="small" />, path: '/otb-counts', divider: true, category_name: 'Customers' },
      { name: 'cpr-stats', short_name: 'CPR Stats', symbol: <FavoriteIcon fontSize="small" />, path: '/cpr-stats', category_name: 'Demographics' },
      { name: 'zip-codes', short_name: 'YOY Sales By Zip', symbol: <LocationOnIcon fontSize="small" />, path: '/zip-code', category_name: 'Demographics' },
      { name: 'demographics', short_name: 'Demo By Location', symbol: <PublicIcon fontSize="small" />, path: '/demographic', category_name: 'Demographics' },
      { name: 'demographic-trends', short_name: 'Demographic Trends', symbol: <TimelineIcon fontSize="small" />, path: '/demographic-trends', category_name: 'Demographics' },
      // { name: 'ltv-by-demo', short_name: 'LTV By Demographic', symbol: <LtvIcon fontSize="small" />, path: '/ltv-by-demo', category_name: 'Demographics' },
      { name: 'customer-retention', short_name: 'Customer Retention', symbol: <SyncIcon fontSize="small" />, path: '/customer-retention', divider: true, category_name: 'Customers' },
    ]
    :    
    currentUser.db_owner === 'RAYMOURFLANIGAN' ?
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
      { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
      { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
      { name: 'lost-opp', short_name: 'Boomerang Lost Opp', symbol: <GpsFixedIcon />, path: '/boomerang/lost-opp', category_name: '' },
      { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
      { name: 'daily-prospecting',  short_name: 'Daily Prospecting', symbol: <RiUserSearchFill style={{fontSize: "25px"}}/>, path: '/daily-prospecting', category_name: '' },
      { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },
      { name: 'product-performance', short_name: 'Product Performance', symbol: <AssignmentIcon />, path: '/product-performance', category_name: '', },
      { name: 'digital', short_name: 'Digital', symbol: <LaptopIcon />, path: '/digital', divider: true, category_name: '', },
      { name: 'rfm-counts', short_name: 'RFM Counts', symbol: <HowToRegIcon fontSize="small"/>, path: '/rfm-counts', category_name: 'Customers' },
      { name: 'otb-counts', short_name: 'OTB Counts', symbol: <ShoppingCartIcon fontSize="small" />, path: '/otb-counts', divider: true, category_name: 'Customers' },
      { name: 'cpr-stats', short_name: 'CPR Stats', symbol: <FavoriteIcon fontSize="small" />, path: '/cpr-stats', category_name: 'Demographics' },
      { name: 'zip-codes', short_name: 'YOY Sales By Zip', symbol: <LocationOnIcon fontSize="small" />, path: '/zip-code', category_name: 'Demographics' },
      { name: 'demographics', short_name: 'Demo By Location', symbol: <PublicIcon fontSize="small" />, path: '/demographic', category_name: 'Demographics' },
      { name: 'demographic-trends', short_name: 'Demographic Trends', symbol: <TimelineIcon fontSize="small" />, path: '/demographic-trends', category_name: 'Demographics' },
      // { name: 'ltv-by-demo', short_name: 'LTV By Demographic', symbol: <LtvIcon fontSize="small" />, path: '/ltv-by-demo', category_name: 'Demographics' },
      { name: 'customer-retention', short_name: 'Customer Retention', symbol: <SyncIcon fontSize="small" />, path: '/customer-retention', divider: true, category_name: 'Customers' },
    ]
    :    
    currentUser.db_owner === "PEPSICO" ?
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
    ]
    :
    [
      { name: 'dashboard', short_name: 'Dashboard', symbol: <DashboardIcon />, path: '/', category_name: '' },
      { name: 'calendar', short_name: 'Calendar', symbol: <TodayIcon />, path: '/calendar', divider: true, category_name: '' },
      { name: 'boomerang', short_name: 'Boomerang', symbol: <BoomIcon />, path: '/boomerang', category_name: '' },
      { name: 'lost-opp', short_name: 'Boomerang Lost Opp', symbol: <GpsFixedIcon />, path: '/boomerang/lost-opp', category_name: '' },
      { name: 'triggers',  short_name: 'Triggers', symbol: <TelegramIcon />, path: '/trigger', category_name: '' },
      { name: 'event-marking', short_name: 'Event Marketing', symbol: <MarkunreadMailboxIcon />, path: '/event-marketing', category_name: '' },
      { name: 'product-performance', short_name: 'Product Performance', symbol: <AssignmentIcon />, path: '/product-performance', divider: true, category_name: '', },
      { name: 'rfm-counts', short_name: 'RFM Counts', symbol: <HowToRegIcon fontSize="small"/>, path: '/rfm-counts', category_name: 'Customers' },
      { name: 'otb-counts', short_name: 'OTB Counts', symbol: <ShoppingCartIcon fontSize="small" />, path: '/otb-counts', divider: true, category_name: 'Customers' },
      { name: 'cpr-stats', short_name: 'CPR Stats', symbol: <FavoriteIcon fontSize="small" />, path: '/cpr-stats', category_name: 'Demographics' },
      { name: 'zip-codes', short_name: 'YOY Sales By Zip', symbol: <LocationOnIcon fontSize="small" />, path: '/zip-code', category_name: 'Demographics' },
      { name: 'demographics', short_name: 'Demo By Location', symbol: <PublicIcon fontSize="small" />, path: '/demographic', category_name: 'Demographics' },
      { name: 'demographic-trends', short_name: 'Demographic Trends', symbol: <TimelineIcon fontSize="small" />, path: '/demographic-trends', category_name: 'Demographics' },
      // { name: 'ltv-by-demo', short_name: 'LTV By Demographic', symbol: <LtvIcon fontSize="small" />, path: '/ltv-by-demo', category_name: 'Demographics' },
      { name: 'customer-retention', short_name: 'Customer Retention', symbol: <SyncIcon fontSize="small" />, path: '/customer-retention', divider: true, category_name: 'Customers' },
    ]

  const sideNav = tableauList ? sideNavItems.concat(JSON.parse(tableauList).filter(item => item.access === 1)) : sideNavItems

  useEffect(() => {
    if (currentUser.db_owner === 'PEPSICO') {
      history.push('/pepsi_location_analysis_v2/Dashboard1_1')
    }
  }, [currentUser])

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical:'top', horizontal:'right'}}
      keepMounted
      transformOrigin={{vertical:'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton color="inherit">
          <Badge overlap={overlap} badgeContent="" variant="dot" color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleOpenNest = (e) => {
    setOpenNest(!openNest)
  }

  const handleOpenDemo = (e) => {
    setOpenDemo(!openDemo)
  }

  const handleOpenMarket = (e) => {
    setOpenMarket(!openMarket)
  }

  const handleOpenPromotions = (e) => {
    setOpenPromotions(!openPromotions)
  }

  const handleOpenSales = (e) => {
    setOpenSales(!openSales)
  }

  const handleOpenTraffic = (e) => {
    setOpenTraffic(!openTraffic)
  }

  const handleOpenYOY = (e) => {
    setOpenYOY(!openYOY)
  }

  const handleOpenTrafficDash = (e) => {
    setOpenTrafficDash(!openTrafficDash)
  }

  const handleOpenLocationDetails = (e) => {
    setOpenLocationDetails(!openLocationDetails)
  }

  const handleOpenShopperTrak = (e) => {
    setOpenShopperTrak(!openShopperTrak)
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleNavOpen = () => {
    setNavOpen(true)
  }


  const handleNavClose = () => {
    setNavOpen(false)
    setOpenNest(false)
    setOpenDemo(false)
    setOpenMarket(false)
    setOpenPromotions(false)
    setOpenSales(false)
    setOpenYOY(false)
    setOpenTrafficDash(false)
    setOpenLocationDetails(false)
    setOpenShopperTrak(false)
  }

  const handleLogout = () => {
    props.handleLogout()
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <MenuList onMouseEnter={handleNavOpen} onMouseLeave={handleNavClose}>

        {/* *** NESTED SIDENAV *** */}
        { tableauList ?
          <div>
            {/* *** Non-Nested MenuItems *** */}
            {
              sideNav.filter(item => item.category_name === '').map(row => (
                <div key={row.path ? row.path : row.display}>
                  <MenuItem
                    className={classes.menu}
                    component={Link}
                    to={row.path ? row.path : '/' + row.display}
                    style={currentUser.admin_level < row.level ? {display: 'none'} : {}}

                  >
                    {/* <div style={{display: 'flex'}}> */}
                      <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                        {/* <Tooltip title={row.short_name}> */}
                        {row.symbol ? row.symbol : <SiTableau />}
                        {/* </Tooltip> */}
                      </ListItemIcon>
                      <ListItemText primary={<Typography className={props.darkMode ? classes.menuTextDark : classes.menuText} variant="caption">{row.short_name.toUpperCase()}</Typography>} />
                    {/* </div> */}
                  </MenuItem>
                  {row.divider ? <Divider /> : null}
                </div>
              ))
            }

            {/* *** Promotions MenuItems *** */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenPromotions}
              style={sideNav.filter(item => item.category_name === 'Promotions').length > 0 ? {} : {display: 'none'}}
              name="Promotions"
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <FilterNoneIcon />
              </ListItemIcon>
              <ListItemText
                  primary={
                    <Typography
                      variant="caption"
                      className={props.darkMode ? classes.menuTextDark : classes.menuText}
                    >
                      PROMOTIONS
                    </Typography>
                  }
              />
              {openPromotions ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openPromotions} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Promotions').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                              >
                                {row.short_name.toUpperCase()}
                              </Typography>
                            }
                          />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                      {/* {row.beta === 0 ? console.log(true) : console.log(false)} */}
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Promotions').length > 0 ? {} : {display: 'none'}}/>

            {/* *** Customers MenuItems *** */}
            <MenuItem
              style={(sideNav.filter(item => item.category_name === 'Customers')).length > 0 ? {} : {display: 'none'}}
              className={classes.menu}
              onClick={e => handleOpenNest(e)}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}><GroupIcon /></ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                    variant="caption"
                  >
                    CUSTOMER
                  </Typography>
                }
              />
              {openNest ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openNest} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Customers').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Customer').length > 0 ? {} : {display: 'none'}} />

            {/* *** Demographics MenuItems *** */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenDemo}
              style={sideNav.filter(item => item.category_name === 'Demographics').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <PieChartIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    DEMOGRAPHICS
                  </Typography>
                }
              />
              {openDemo ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openDemo} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Demographics').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Demographics').length > 0 ? {} : {display: 'none'}} />

            {/* *** Marketing MenuItems *** */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenMarket}
              style={sideNav.filter(item => item.category_name === 'Marketing').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    MARKETING
                  </Typography>
                }
              />
              {openMarket ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openMarket} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Marketing').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Marketing').length > 0 ? {} : {display: 'none'}}/>

            {/* *** Sales/old_Dashboard MenuItems *** */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenSales}
              style={sideNav.filter(item => item.category_name === 'Dashboard').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    SALES
                  </Typography>
                }
              />
              {openSales ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openSales} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Dashboard').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Dashboard').length > 0 ? {} : {display: 'none'}}/>

            {/* *** Traffic MenuItems *** */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenTraffic}
              style={sideNav.filter(item => item.category_name === 'Traffic').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <TrafficIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    TRAFFIC
                  </Typography>
                }
              />
              {openTraffic ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openTraffic} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Traffic').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Traffic').length > 0 ? {} : {display: 'none'}}/>

            {/* *** ACCOUNTS SPECIFIC TO TRAFFIC MenuItems *** */}
            {/* year over year */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenYOY}
              style={sideNav.filter(item => item.category_name === 'Year_Over_Year').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    Year Over Year
                  </Typography>
                }
              />
              {openYOY ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openYOY} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Year_Over_Year').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Year_Over_Year').length > 0 ? {} : {display: 'none'}}/>
            {/* Traffic old_Dashboard */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenTrafficDash}
              style={sideNav.filter(item => item.category_name === 'Traffic_Dashboard').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <TrafficIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    Traffic
                  </Typography>
                }
              />
              {openTrafficDash ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openTrafficDash} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Traffic_Dashboard').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Traffic_Dashboard').length > 0 ? {} : {display: 'none'}}/>
            {/* Location Details */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenLocationDetails}
              style={sideNav.filter(item => item.category_name === 'Location_Details').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    Location Details
                  </Typography>
                }
              />
              {openLocationDetails ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openLocationDetails} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'Location_Details').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                            >
                              {row.short_name.toUpperCase()}
                            </Typography>
                          }
                        />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'Location_Details').length > 0 ? {} : {display: 'none'}}/>
            {/* ShopperTrak */}
            <MenuItem
              className={classes.menu}
              onClick={handleOpenShopperTrak}
              style={sideNav.filter(item => item.category_name === 'ShopperTrak').length > 0 ? {} : {display: 'none'}}
            >
              <ListItemIcon className={props.darkMode ? classes.menuIconDark : classes.menuIcon}>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    className={props.darkMode ? classes.menuTextDark : classes.menuText}
                  >
                    ShopperTrak
                  </Typography>
                }
              />
              {openShopperTrak ? <ExpandLessIcon style={props.darkMode ? {color: '#fff'} : {color: '#757575'}} /> : <ExpandMoreIcon style={{color: '#757575'}} />}
            </MenuItem>
            <Collapse in={openShopperTrak} timeout="auto" unmountOnExit>
              <MenuList className={classes.nested} style={{justifyContent: 'space-between'}}>
                {
                  sideNav.filter(item => item.category_name === 'ShopperTrak').map(row => (
                    <MenuItem
                      key={row.path ? row.path : '/' + row.display}
                      component={Link}
                      to={row.path ? row.path : '/' + row.display}
                    >
                      <div style={{display: 'inline-flex', alignItems: 'center'}}>
                        <ListItemIcon>
                          {/* <Tooltip title={row.short_name}> */}
                          {row.symbol ? row.symbol : <SiTableau />}
                          {/* </Tooltip> */}
                        </ListItemIcon>
                        <ListItemText
                          secondary={
                            <Typography
                              variant="caption"
                              className={props.darkMode ? classes.nestedTextDark : classes.nestedText}
                              >
                                {row.short_name.toUpperCase()}
                              </Typography>
                            }
                          />
                      </div>
                      <ListItemIcon className={classes.betaIcon} style={row.beta === 1 ? {} : {display: 'none'} }>
                        <IoConstructOutline />
                        <Typography style={{color: '#d19608', fontSize: '8px'}} variant="caption">βETA</Typography>
                      </ListItemIcon>
                    </MenuItem>
                  ))
                }
              </MenuList>
            </Collapse>
            <Divider style={sideNav.filter(item => item.category_name === 'ShopperTrak').length > 0 ? {} : {display: 'none'}}/>
          </div>
          : null
        }
      </MenuList>
    </div>
  )

  return (
    <Fragment>

      <CssBaseline/>
        <div className={classes.root}>
      <CssBaseline />
        <AppBar
          position="fixed"
          className={
            props.darkMode ? clsx(classes.appBarDark, {[classes.appBarShift]: open,}) : clsx(classes.appBar, {[classes.appBarShift]: open,})
          }
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div style={{display: 'flex'}}>
              <div>
                <img src={props.darkMode ? lsLogoDark : lsLogo} style={{width: '90px', marginLeft: '-5px', marginTop: '1.5px'}} />
              </div>
              {/* <div style={{borderLeft: '1px solid #c5c5c5', marginLeft: '5px', opacity: 0.7, marginTop: '-1px'}} />
              <div>
                <img src={props.darkMode ? logoDark : logo} style={{width: '90px', marginLeft: '5px'}} />
              </div> */}
            </div>
            <Divider orientation="vertical" />
            <div className={classes.grow} />
            <div className={classes.sectionDesktop} style={{alignItems: 'center'}}>
                <div style={{borderRight: '1px solid #c5c5c5', padding: '1em'}}>
                  <div>
                    <img src={props.darkMode ? logoDark : logo} style={{width: '90px', marginRight: '5px'}} />
                  </div>
                  {/* <div style={{borderLeft: '3px solid #c5c5c5', marginLeft: '5px', opacity: 0.7,}} /> */}
                </div>

                <Notifications />
                <Tooltip title="Profile">
                  <IconButton aria-label="open drawer" edge="end" onClick={handleDrawerOpen} className={clsx(open && classes.hide)}>
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
              {/* </div> */}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton onClick={handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {isLoading ? <CircularProgress /> :
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{paper: classes.profileDrawerPaper}}
        >
          <div className={classes.drawerHeader}>
            <AccountCircle fontSize="large" className={props.darkMode ? classes.avatarIconDark : classes.avatarIcon}/>
            <div className={props.darkMode ? classes.avatarDark : classes.avatar}>
              <Typography variant="caption" style={{fontWeight: 550}}>{(currentUser.fname).toUpperCase()}</Typography>
            </div>
            <Tooltip title="Close Drawer">
              <IconButton className="grow" onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Tooltip>
          </div>
          <Divider />
          <List>
            {/* <UserProfileMenu currentUser={currentUser}/>
            <ListItem button component={Link} to={'/update-user'}>
              <ListItemIcon><Settings/></ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </ListItem> */}

            <AdminMenu
              handleDrawerClose={handleDrawerClose}
              user={currentUser.admin_level}
              darkMode={props.darkMode}
            />

            <Divider />
            <ListItem button component={Link} to={'/'} onClick={handleLogout}>
              {/* <ListItemIcon><ExitToApp/></ListItemIcon> */}
              <ListItemIcon><PowerSettingsNewIcon/></ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItem>
            <Divider />
          </List>

        </Drawer>
      }
        <nav className={classes.nav}>

          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              className={clsx(classes.navBar, {
                [classes.navOpen]: navOpen,
                [classes.navClose]: !navOpen,
              })}
              classes={{
                paper: clsx({
                  [classes.navOpen]: navOpen,
                  [classes.navClose]: !navOpen,
                }),
              }}
              open={navOpen}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: navOpen,
          })}>
          <div className={classes.toolbar} />
          <div>
            {children}
          </div>
        </main>
      </div>

    </Fragment>
  )
}

export default ResponsiveDrawer
